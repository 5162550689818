import React from "react";
import { useSelector } from "react-redux";

const UpgradeButton = () => {
  const user = useSelector((state) => state.home.user);

  const isFreeUser = user?.MembershipText === "Free User";
  const isCenturion = user?.IsCenturion;
  const isMars = user?.IsMars;

  const MEMBERSHIP_CONFIG = {
    FREE: {
      text: "Upgrade Now",
      subText: "30-Day Trial",
      url: "https://spiking.com/purchase/spiking-ai?discount=30TRIAL",
    },
    REFERRAL: {
      text: "Refer A Friend",
      subText: null,
      url: "/refer",
    },
    PREMIUM: {
      text: "🔥 Upgrade To\nMars Unlimited",
      subText: null,
      url: "https://spiking.com/purchase/mars-mastermind?discount=MARS",
    },
  };

  const getButtonConfig = () => {
    if (isFreeUser) return MEMBERSHIP_CONFIG.FREE;
    if (isCenturion || isMars) return MEMBERSHIP_CONFIG.REFERRAL;
    return MEMBERSHIP_CONFIG.PREMIUM;
  };

  const handleClick = () => {
    const { url } = getButtonConfig();
    window.location.href = url;
  };

  const { text, subText } = getButtonConfig();

  return (
    <button
      onClick={handleClick}
      className="relative inline-flex flex-col items-center justify-center px-4 py-2 my-2 text-white rounded-lg transition-all duration-300 shadow-lg hover:shadow-xl"
      style={{
        background:
          "linear-gradient(90deg, #00BCE6 0%, #2962FF 49.17%, #D500F9 98.34%)",
      }}
    >
      <span className="text-base font-semibold whitespace-pre-line">{text}</span>
      {subText && <span className="text-xs opacity-90 mt-0.5">{subText}</span>}
    </button>
  );
};

export default UpgradeButton;
